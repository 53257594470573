//Meeting.jsx
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSocket } from "./socketservice";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import styles from "./Meeting.module.css";

import {
  Mic,
  MicOff,
  Video,
  VideoOff,
  Share2,
  MessageCircle,
  MoreHorizontal,
  PhoneOff,
  ChevronDown,
  UserPlus,
  Pin,
  Maximize2,
  Settings,
  Users,
  Hand,
} from "lucide-react";
import { ScrollArea } from "./ui/scroll-area";
import Participants from "./participants";

const PC_CONFIG = {
  iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
};

export default function Meeting() {
  const { meetingId } = useParams(); // Changed from roomId to meetingId to match route parameter
  const navigate = useNavigate();

  const localStreamRef = useRef(null);
  const [participants, setParticipants] = useState([]);
  const [remoteStreams, setRemoteStreams] = useState({});
  const socketRef = useRef(getSocket());
  const peerConnectionsRef = useRef({});
  const localVideoRef = useRef(null);

  const [users, setUsers] = useState();
  const [chats, setChats] = useState();
  const [showParticipants, setShowParticipants] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isRaisingHand, setIsRaisingHand] = useState(false);
  const videoRef = useRef(null);
  const screenShareRef = useRef(null);
  const fullScreenRef = useRef(null);

  const hostUser = {
    id: 1,
    name: "Ram Kumar",
    role: "CEO",
    isHost: true,
    video: true,
    audio: true,
    pin: true,
    screen: false,
  };

  const createPeerConnection = (userId, initiator = false) => {
    try {
      console.log(
        "Creating peer connection for:",
        userId,
        "initiator:",
        initiator
      );
      const pc = new RTCPeerConnection(PC_CONFIG);

      // Add local stream tracks to peer connection
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach((track) => {
          console.log("Adding track to peer connection:", track.kind);
          pc.addTrack(track, localStreamRef.current);
        });
      }

      // Handle ICE candidates
      pc.onicecandidate = (event) => {
        if (event.candidate) {
          console.log("Sending ICE candidate to:", userId);
          socketRef.current.emit("ice-candidate", {
            target: userId,
            candidate: event.candidate,
          });
        }
      };

      // Handle connection state changes with more detailed logging
      pc.onconnectionstatechange = () => {
        console.log(
          `Connection state change for ${userId}:`,
          pc.connectionState
        );
        if (pc.connectionState === "failed") {
          console.log("Connection failed, recreating peer connection");
          pc.close();
          createPeerConnection(userId, initiator);
        }
      };

      // Enhanced logging for stream handling
      pc.ontrack = (event) => {
        console.log("Received track from:", userId, event.streams[0]);
        if (event.streams[0]) {
          setRemoteStreams((prev) => ({
            ...prev,
            [userId]: event.streams[0],
          }));
        }
      };

      peerConnectionsRef.current[userId] = pc;

      if (initiator) {
        console.log("Creating offer for:", userId);
        pc.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
          .then((offer) => {
            console.log("Setting local description");
            return pc.setLocalDescription(offer);
          })
          .then(() => {
            console.log("Sending offer to:", userId);
            socketRef.current.emit("offer", {
              target: userId,
              offer: pc.localDescription,
            });
          })
          .catch((err) => console.error("Error creating offer:", err));
      }

      return pc;
    } catch (err) {
      console.error("Error creating peer connection:", err);
      return null;
    }
  };

  useEffect(() => {
    console.log("Current meetingId from URL:", meetingId);

    if (!meetingId) {
      console.error("No meeting ID provided");
      navigate("/");
      return;
    }
  }, [meetingId, navigate]);

  // Media setup effect
  useEffect(() => {
    if (!meetingId) return;

    async function setupMedia() {
      try {
        console.log("Setting up media devices for meeting:", meetingId);
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        console.log("Got media stream:", stream);
        localStreamRef.current = stream;
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        // Join meeting after getting media
        console.log("Joining meeting:", meetingId);
        socketRef.current.emit("join-room", { roomId: meetingId }); // Note: sending as roomId to match server expectations
      } catch (err) {
        console.error("Failed to get media devices:", err);
        alert(
          "Failed to access camera/microphone. Please ensure permissions are granted."
        );
      }
    }

    setupMedia();

    return () => {
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [meetingId]);

  // Socket event handlers
  useEffect(() => {
    const socket = socketRef.current;

    socket.on("joined-room", ({ participants: roomParticipants }) => {
      console.log("Joined room with participants:", roomParticipants);
      setParticipants(roomParticipants);

      // Create peer connections with existing participants
      roomParticipants.forEach((participantId) => {
        if (
          participantId !== socket.id &&
          !peerConnectionsRef.current[participantId]
        ) {
          createPeerConnection(participantId, true);
        }
      });
    });

    socket.on("user-joined", ({ userId, participants: roomParticipants }) => {
      console.log("User joined:", userId);
      setParticipants(roomParticipants);
    });

    socket.on("offer", async ({ offer, sender }) => {
      console.log("Received offer from:", sender);
      let pc = peerConnectionsRef.current[sender];

      if (!pc) {
        pc = createPeerConnection(sender, false);
      }

      try {
        await pc.setRemoteDescription(new RTCSessionDescription(offer));
        console.log("Creating answer for:", sender);
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);

        socket.emit("answer", {
          target: sender,
          answer: pc.localDescription,
        });
      } catch (err) {
        console.error("Error handling offer:", err);
      }
    });

    socket.on("answer", async ({ answer, sender }) => {
      console.log("Received answer from:", sender);
      const pc = peerConnectionsRef.current[sender];
      if (pc) {
        try {
          await pc.setRemoteDescription(new RTCSessionDescription(answer));
        } catch (err) {
          console.error("Error setting remote description:", err);
        }
      }
    });

    socket.on("ice-candidate", async ({ candidate, sender }) => {
      console.log("Received ICE candidate from:", sender);
      const pc = peerConnectionsRef.current[sender];
      if (pc) {
        try {
          await pc.addIceCandidate(new RTCIceCandidate(candidate));
        } catch (err) {
          console.error("Error adding ICE candidate:", err);
        }
      }
    });

    socket.on("user-left", ({ userId, participants: roomParticipants }) => {
      console.log("User left:", userId);
      setParticipants(roomParticipants);

      if (peerConnectionsRef.current[userId]) {
        peerConnectionsRef.current[userId].close();
        delete peerConnectionsRef.current[userId];
      }

      setRemoteStreams((prev) => {
        const updated = { ...prev };
        delete updated[userId];
        return updated;
      });
    });

    return () => {
      Object.values(peerConnectionsRef.current).forEach((pc) => pc.close());
      peerConnectionsRef.current = {};
      socket.off("joined-room");
      socket.off("user-joined");
      socket.off("offer");
      socket.off("answer");
      socket.off("ice-candidate");
      socket.off("user-left");
    };
  }, [meetingId]);

  useEffect(() => {
    console.log("Meeting component mounted with parameters:", {
      meetingId,
      socketId: socketRef.current?.id,
      meetingData: JSON.parse(localStorage.getItem("meetingData") || "{}"),
    });
  }, [meetingId]);

  const setVideoStream = (videoElement, stream) => {
    if (videoElement && stream) {
      console.log("Setting video stream:", stream.id);
      try {
        videoElement.srcObject = stream;
      } catch (err) {
        console.error("Error setting video stream:", err);
        videoElement.src = URL.createObjectURL(stream);
      }
    }
  };

  function formatDateTime() {
    const now = new Date();

    // Helper function to get the ordinal suffix for the day
    function getOrdinal(day) {
      const suffixes = ["th", "st", "nd", "rd"];
      const mod = day % 10;
      return mod >= 1 && mod <= 3 && Math.floor(day / 10) !== 1
        ? day + suffixes[mod]
        : day + suffixes[0];
    }

    // Format the month (e.g., "Nov")
    const options = { month: "short", day: "numeric", year: "numeric" };
    const dateString = now.toLocaleDateString("en-US", options).split(" ");

    // Get formatted date
    const month = dateString[0];
    const day = getOrdinal(parseInt(dateString[1]));
    const year = dateString[2];

    // Get time in AM/PM format
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes; // pad minutes with leading zero if needed

    const timeString = `${hours}:${minutes} ${ampm}`;

    // Combine the date and time into the desired format
    return `${month} ${day}, ${year} | ${timeString}`;
  }

  const getInitials = (name) => {
    const names = name.split(" ");
    return names.length > 1
      ? names[0][0] + names[1][0]
      : names[0][0] + names[0][1];
  };

  return (
    <div className="flex h-screen bg-background">
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between p-1 border-b">
          <div className="flex items-center justify-between gap-4">
            <img src="/assets/meet/video.png" />
            <div>
              <h1 className="text-xl font-semibold">
                Weekly Report Marketing + Sales On Fire...!
              </h1>
              <p className="text-sm text-muted-foreground">
                {formatDateTime()}
              </p>
            </div>
          </div>

          {/* <div className="flex items-center gap-2">
            <div className="flex -space-x-2">
              {['f','ffe'].map((user, i) => (
                <div
                  key={user.id}
                  className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center ring-2 ring-background"
                >
                  <span className="text-sm font-medium">
                    {getInitials(user)}
                  </span>
                </div>
              ))}
              {true && (
                <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center ring-2 ring-background">
                  <span className="text-sm font-medium">+{6 - 4}</span>
                </div>
              )}
            </div>

            <div className="flex items-center border-round bg-red">
              <Share2 className="h-4 w-4 mr-2" />
              pmb-alab-mtb
            </div>
          </div> */}
        </div>

        <div className="flex gap-2">
          {/* Main content */}
          <div className="flex-1 flex flex-col max-w-[80vw] overflow-auto">
            {/* Host's big video screen */}

            <div className="flex-1 relative w-full rounded-lg max-h-[60vh] overflow-hidden bg-muted mb-2 border-2">
              <video
                ref={localVideoRef}
                autoPlay
                playsInline
                muted
                className="w-full h-full border-4 border-blue-500 object-cover"
              />

              <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
                <div className="flex items-center justify-between">
                  <span className="text-white font-medium">
                    {hostUser.name} (Host)
                  </span>
                  <div className="flex items-center gap-2">
                    {!hostUser.audio && (
                      <MicOff
                        className="w-4 h-4 text-white"
                        aria-label="Microphone Off"
                      />
                    )}
                    {!hostUser.video && (
                      <VideoOff
                        className="w-4 h-4 text-white"
                        aria-label="Video Off"
                      />
                    )}
                  </div>
                </div>
              </div>

              <Button
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2"
                // onClick={() => togglePin(hostUser.id)}
                aria-label={`Pin ${hostUser.name}'s Video`}
              >
                <Pin
                  className={`h-4 w-4 ${
                    hostUser.pin ? "text-blue-500" : "text-white"
                  }`}
                  aria-label="Pin Video"
                />
              </Button>

              <Button
                variant="ghost"
                size="icon"
                className="absolute top-2 left-2"
                // onClick={toggleScreenShare}
                aria-label={
                  isScreenSharing ? "Stop Screen Sharing" : "Share Screen"
                }
              >
                <VideoOff
                  className={`h-4 w-4 ${
                    isScreenSharing ? "text-red-500" : "text-white"
                  }`}
                  aria-label={
                    isScreenSharing ? "Stop Screen Sharing" : "Share Screen"
                  }
                />
              </Button>
            </div>

            {/* Other participants */}
            <div className="flex-none w-400 h-64 overflow-x-auto scrollbar-hide">
              <div className="flex gap-2">
                {Object.entries(remoteStreams).map(
                  ([participantId, stream]) => {
                    console.log(
                      "Rendering video for:",
                      participantId,
                      "Stream:",
                      stream
                    );
                    return (
                      <div
                        key={participantId}
                        className={`relative w-96 h-64 rounded-lg overflow-hidden bg-muted flex-shrink-0 border-4 border-blue-500`}
                      >
                        <video
                          autoPlay
                          playsInline
                          ref={(video) => {
                            if (video) {
                              video.onerror = (e) =>
                                console.error("Video error:", e);
                              setVideoStream(video, stream);
                            }
                          }}
                          className="w-full h-full object-cover"
                        />
                        <div className={styles.videoLabel}>
                          Participant {participantId.slice(0, 8)}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
          <Participants />

            {/* Bottom Icon Navigation */}
            <div className="flex justify-evenly p-4 border-t bg-muted">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  // setShowParticipants(true);
                  // setShowChat(false);
                }}
              >
                <Users className="h-5 w-5" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setShowParticipants(false);
                  setShowChat(true);
                }}
              >
                <MessageCircle className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>

        {/* Controls */}
        <div className="flex justify-evenly items-center">
          <div className="p-4 border-t flex items-center justify-center">
            <div className="flex items-center justify-center gap-2">
              {/* <Button
                variant={isMuted ? "destructive" : "secondary"}
                size="icon"
                onClick={() => toggleUserAudio(1)}
              >
                {isMuted ? (
                  <MicOff className="h-4 w-4" />
                ) : (
                  <Mic className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant={isVideoOff ? "destructive" : "secondary"}
                size="icon"
                onClick={() => toggleUserVideo(1)}
              >
                {isVideoOff ? (
                  <VideoOff className="h-4 w-4" />
                ) : (
                  <Video className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant={isScreenSharing ? "destructive" : "secondary"}
                size="icon"
                onClick={toggleScreenShare}
              >
                <Share2 className="h-4 w-4" />
              </Button> */}
              {/* <Button
              variant="secondary"
              size="icon"
              onClick={() => setShowChat(!showChat)}
            >
              <MessageCircle className="h-4 w-4" />
            </Button> */}
              {/* <Button
                variant={isRaisingHand ? "destructive" : "secondary"}
                size="icon"
                onClick={() => setIsRaisingHand(!isRaisingHand)}
              >
                <Hand className="h-4 w-4" />
              </Button> */}
              {/* <Button
                variant="secondary"
                size="icon"
                // onClick={toggleFullScreen}
              >
                <Maximize2 className="h-4 w-4" />
              </Button> */}
              {/* <Button variant="secondary" size="icon">
              <Settings className="h-4 w-4" />
            </Button> */}
            </div>
          </div>

          <div className="flex p-4 items-center bg-red text-white rounded-[80px]">
            <PhoneOff className="h-4 w-4 mr-2" />
            End Call
          </div>
        </div>
      </div>
    </div>
  );
}
