import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Meeting from './Meeting';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('lionsmeet'));
root.render(
  // <React.StrictMode>
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/meeting/:meetingId" element={<Meeting />} />
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
