//docketervices.js
import { io } from "socket.io-client";
import config from './config';

let globalSocket = null;
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;

export const getSocket = () => {
  if (!globalSocket) {
    globalSocket = io(config.socketUrl, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: 1000,
      timeout: 60000,
      autoConnect: true
    });

    globalSocket.on('connect', () => {
      console.log('Socket connected:', globalSocket.id);
      reconnectAttempts = 0;
    });

    globalSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      reconnectAttempts++;
      
      if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        console.log('Max reconnection attempts reached');
        window.location.href = '/';
      }
    });

    globalSocket.on('connect_error', (error) => {
      console.log('Connection error:', error);
    });
  }

  return globalSocket;
};

export const disconnectSocket = () => {
  if (globalSocket) {
    globalSocket.disconnect();
    globalSocket = null;
    reconnectAttempts = 0;
  }
};