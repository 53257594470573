// PopupModal.js
import React, { useState } from 'react';

const PopupModal = ({ isOpen, onClose, onSubmitUsers }) => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
  
    const addUser = () => {
      if (name && email) {
        const newUser = { name, email };
        setUsers([...users, newUser]);
        setName('');
        setEmail('');
      }
    };
  
    const removeUser = (index) => {
      const updatedUsers = users.filter((_, i) => i !== index);
      setUsers(updatedUsers);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmitUsers(users); // Pass users data back to parent
      onClose(); // Close the modal
    };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg w-[90vw] max-w-[400px] shadow-lg">
          {/* Modal Header */}
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Manage Users</h3>
            <button className="text-gray-500" onClick={onClose}>X</button>
          </div>

          {/* Modal Content (Form) */}
          <form className="space-y-4" onSubmit={handleSubmit}>
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter your name"
              />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter your email"
              />
            </div>

            {/* Plus and Minus Buttons */}
            <div className="flex items-center space-x-4 mt-4">
              <button
                type="button"
                className="p-2 bg-blue-500 text-white rounded-full"
                onClick={addUser}
              >
                +
              </button>
            </div>

            {/* Display Users */}
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-700">Users List</h4>
              <ul className="space-y-2 mt-2">
                {users.length === 0 ? (
                  <li>No users added</li>
                ) : (
                  users.map((user, index) => (
                    <li key={index} className="text-sm flex justify-between items-center">
                      <span>{user.name} ({user.email})</span>
                      <button
                        onClick={() => removeUser(index)}
                        className="text-red-500 ml-2 text-sm"
                      >
                        Remove
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>

            {/* Submit Button */}
            <div className="mt-4 text-center">
              <button type="submit" className="w-full py-2 bg-green-500 text-white rounded-lg">Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default PopupModal;
