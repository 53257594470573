import React from 'react';

import {
    Mic,
    MicOff,
    Video,
    VideoOff,
    Share2,
    MessageCircle,
    MoreHorizontal,
    PhoneOff,
    ChevronDown,
    UserPlus,
    Pin,
    Maximize2,
    Settings,
    Users,
    Hand,
} from "lucide-react";

import { Button } from "./ui/button";

export default function Participants({ participants=[] }) {
    return (
        <div className="w-80 flex flex-col h-full max-h-[84vh] border border-gray-400 rounded-lg">
            {/* Participants Section */}
            <div
                className={`flex-1 block transition-all`}
                style={{ minHeight: "calc(100vh - 4rem)" }}
            >
                <div className="p-4 bg-white rounded-lg rounded">
                    <button
                        className="flex items-center justify-between w-full hover:bg-muted"
                    >
                        <span className="font-medium">Participants</span>
                        <ChevronDown
                            className={`h-4 w-4 transform rotate-180`}
                        />
                    </button>
                </div>
                <div className="px-3 py-4 flex flex-col gap-2">
                    {participants.map((user, i) => (
                        <div
                            key={user.id}
                            className="flex bg-white rounded items-center justify-between py-2"
                        >
                            <div className="flex items-center gap-2">
                                <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                                    <span className="text-sm font-medium">
                                        {/* {getInitials(user.name)} */}
                                    </span>
                                </div>
                                <div>
                                    <div className="font-medium">{user.name}</div>
                                    {user.role && (
                                        <div className="text-xs text-muted-foreground">
                                            {'user ' + (i + 1)}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="ghost"
                                    size="icon"
                                // onClick={() => toggleUserAudio(user.id)}
                                >
                                    {user.audio ? (
                                        <Mic className="h-4 w-4" />
                                    ) : (
                                        <MicOff className="h-4 w-4" />
                                    )}
                                </Button>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                // onClick={() => toggleUserVideo(user.id)}
                                >
                                    {user.video ? (
                                        <Video className="h-4 w-4" />
                                    ) : (
                                        <VideoOff className="h-4 w-4" />
                                    )}
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}